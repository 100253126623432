
import {defineComponent, onMounted} from 'vue'

import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import ClinicsCabinetsList from "@/components/clinics/cabinets/ClinicsCabinetsList.vue";

export default defineComponent({
  components: {ClinicsCabinetsList},
  setup() {
    const cabinetsStore = useCabinetsStore()
    onMounted(async () => {
      await Promise.all([cabinetsStore.setCabinets(false)])
    })
    return {}
  }
})
