
import { defineComponent, ref } from "vue";
import ButtonAdd from "../../../utils/buttons/ButtonAdd.vue";
import AddClinicCabinetModal from "@/components/clinics/cabinets/addcabinet/AddClinicCabinetModal.vue";

export default defineComponent({
  name: "AddClinicCabinet",
  components: { AddClinicCabinetModal, ButtonAdd },
  setup() {
    const addClinicCabinetModal = ref(false);
    return { addClinicCabinetModal };
  },
});
