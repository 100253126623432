
import { defineComponent, ref } from "vue";
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import ClinicsCabinetsListBody from "@/components/clinics/cabinets/ClinicsCabinetsListBody.vue";
import ClinicsCabinetsListHeader from "@/components/clinics/cabinets/ClinicsCabinetsListHeader.vue";
import InputField from "@/components/utils/input/InputField.vue";
import AddClinicCabinet from "@/components/clinics/cabinets/addcabinet/AddClinicCabinet.vue";
import { useUserStore } from "@/store/user/UserStore";

export default defineComponent({
  name: "ClinicsCabinetsList",
  components: {
    InputField,
    ClinicsCabinetsListHeader,
    ClinicsCabinetsListBody,
    BaseTableWrapper,
    AddClinicCabinet,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const nameSearchInput = ref<string | undefined>();
    return { nameSearchInput, userState };
  },
});
