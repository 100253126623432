
import {computed, defineComponent} from 'vue'
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import ClinicsCabinetsListItem from "@/components/clinics/cabinets/ClinicsCabinetsListItem.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "ClinicsCabinetsListBody",
  components: {ClinicsCabinetsListItem, BaseTableBodyWrapper},
  props: {
    nameFilterInput: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()
    const allCabinets = computed(() => cabinetsState.cabinets)
    const cabinets = computed(() => activeClinicUID.value ? allCabinets.value.filter(cabinet => cabinet.clinicUID === activeClinicUID.value) : allCabinets.value)
    const filteredCabinets = computed(
        () => props.nameFilterInput?.length
            ? cabinets.value.filter(c => c.name.toLowerCase().includes((props.nameFilterInput || '').toLowerCase()))
            : cabinets.value
    )
    return {filteredCabinets}
  }
})
