
import {computed, defineComponent, onBeforeMount, PropType, ref} from "vue";
import {useAppStore} from "@/store/app/AppStore";
import {AddCabinetInput} from "@/shared/types";
import InputField from "@/components/utils/input/InputField.vue";
import ClinicsDropdown from "@/components/clinics/utils/ClinicsDropdown.vue";
import {TYPE} from "vue-toastification";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import LicenceNosDropdown from "@/components/clinics/addclinic/utils/LicenceNosDropdown.vue";

export default defineComponent({
  components: {LicenceNosDropdown, ClinicsDropdown, InputField},
  emits: {
    onCabinet: (cabinet: unknown) => !!cabinet,
  },
  props: {
    cabinet: {
      type: Object as PropType<AddCabinetInput>,
      required: false,
    },
  },
  setup(props, {emit}) {
    const name = ref<string | null>(null);
    const clinicUID = ref<string | null>(null);
    const licenceNo = ref<string | null>(null)

    const appStore = useAppStore();
    const clinicsStore = useClinicsStore();
    const clinicsState = clinicsStore.getState();
    const activeClinicUID = computed(() => clinicsState.activeClinicUID);
    onBeforeMount(() => {
      if (!props.cabinet) return
      name.value = props.cabinet.name;
      clinicUID.value = props.cabinet.clinicUID;
      licenceNo.value = props.cabinet.licenceNo
    });

    const onLicenceNo = (selected?: string) => licenceNo.value = selected ?? null

    const checkAndGetNewClinic = (): AddCabinetInput | undefined => {
      if (!name?.value)
        appStore.showToast(TYPE.WARNING, "Palun sisestage kabineti nimi");
      else if (!clinicUID?.value)
        appStore.showToast(TYPE.WARNING, "Palun valige kliinik");
      else if (!licenceNo?.value) appStore.showToast(TYPE.WARNING, 'Palun valige kiirgustegevusloa number')
      else {
        return {
          name: name.value,
          clinicUID: clinicUID.value,
          licenceNo: licenceNo.value
        };
      }
    };

    const onClinicCabinetAdd = async () => {
      const cabinet = checkAndGetNewClinic();
      if (cabinet) emit("onCabinet", cabinet);

    };
    return {name, clinicUID, onClinicCabinetAdd, activeClinicUID, onLicenceNo, licenceNo};
  },
});
