import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyButton = _resolveComponent("CopyButton")!
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!
  const _component_EditClinicCabinetModal = _resolveComponent("EditClinicCabinetModal")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.cabinet.name), 1),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_CopyButton, {
        content: _ctx.cabinet.uid
      }, null, 8, ["content"])
    ]),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.cabinet.xRayEquipmentsUID.length), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.clinic?.name), 1),
    _createElementVNode("td", {
      class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyCabinetUID && _ctx.copyCabinetUID(...args)))
    }, _toDisplayString(_ctx.cabinet.uid), 1),
    _createElementVNode("td", {
      class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700 cursor-pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyCabinetUID && _ctx.copyCabinetUID(...args)))
    }, _toDisplayString(_ctx.cabinet.licenceNo), 1),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(_component_BaseTableEditDeleteButtons, {
        "show-delete": _ctx.isAdmin,
        onOnDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteCabinetModal = true)),
        onOnEdit: _ctx.onEditCabinet
      }, null, 8, ["show-delete", "onOnEdit"]),
      (_ctx.deleteCabinetModal)
        ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
            key: 0,
            onOnAction: _ctx.onDeleteModalClose
          }, null, 8, ["onOnAction"]))
        : _createCommentVNode("", true),
      (_ctx.editCabinetModal)
        ? (_openBlock(), _createBlock(_component_EditClinicCabinetModal, {
            key: 1,
            onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editCabinetModal = false)),
            cabinet: _ctx.cabinet
          }, null, 8, ["cabinet"]))
        : _createCommentVNode("", true)
    ])
  ]))
}