
import {computed, defineComponent, PropType, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import {AddCabinetInput, CabinetDto} from "@/shared/types";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import ClinicCabinetForm from "@/components/forms/ClinicCabinetForm.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "EditClinicCabinetModal",
  components: {ClinicCabinetForm, ModalWrapper},
  emits: ['onClose'],
  props: {
    cabinet: {
      type: Object as PropType<CabinetDto>,
      required: true
    }
  },
  setup(props, {emit}) {
    const isOpen = ref(true)
    const cabinetsStore = useCabinetsStore()
    const clinicsStore = useClinicsStore()
    const cabinetInput = computed<AddCabinetInput>(() => ({
      name: props.cabinet.name,
      clinicUID: props.cabinet.clinicUID,
      licenceNo: props.cabinet.licenceNo
    }))

    const onCabinetEdit = async (cabinet: AddCabinetInput) => {
      await cabinetsStore.editCabinet({uid: props.cabinet.uid, ...cabinet})
      await clinicsStore.setClinics(true)
      emit('onClose')
    }
    return {isOpen, onCabinetEdit, cabinetInput}
  }
})
