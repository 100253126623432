
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {unref as v} from "@vue/reactivity";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import InputField from "@/components/utils/input/InputField.vue";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";

export default defineComponent({
  name: "LicenceNosDropdown",
  components: {MSimpleDropdownButton, MSimpleDropdown, MSimpleDropdownOption},
  emits: {
    onLicenceNo: (licenceNo: unknown) => typeof licenceNo === "string" || true
  },
  props: {
    clinicUID: {
      type: String,
      required: false
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
    cabinetUID: {
      type: String,
      required: false
    },
    initialLicenceNo: {
      type: String,
      required: false
    }
  },
  setup(props, {emit}) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()

    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()

    const licenceNoByCabinet = computed(() => cabinetsState.cabinets.find(cabinet => cabinet.uid === props.cabinetUID)?.licenceNo)


    const clinics = computed(() => clinicsState.clinics)
    const selectedClinics = computed(() => props.clinicUID ? clinics.value.filter(clinic => clinic.uid === props.clinicUID) : clinics.value)

    const licenceNos = computed(() => {
      if (licenceNoByCabinet.value) return [licenceNoByCabinet.value]
      return [...new Set(selectedClinics.value.flatMap(clinic => clinic.licenseNos))]
    })
    const licenceNoSearch = ref<string>('')
    const filteredLicenceNos = computed(
        () => licenceNoSearch.value
            ? licenceNos.value.filter(l => l.toLowerCase().includes(licenceNoSearch.value.toLowerCase()))
            : licenceNos.value
    )
    const selected = ref<string | undefined>(undefined)
    const isDropdown = ref(false)
    const dropdownID = 'licence-nos-dd'
    const buttonText = computed(() => selected.value || '')
    const title = computed(() => selected.value ? 'Kiirgustegevuse luba' : 'Palun vali kiirgustegevuse luba')
    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue
    onMounted(async () => {
      await clinicsStore.setClinics(false)
      await cabinetsStore.setCabinets(false)
      if (props.initialLicenceNo) selectOption(props.initialLicenceNo)
      await
          window.addEventListener("click", (e: MouseEvent) => {
            if (v(isDropdown)) {
              const target = e.target as HTMLDivElement
              if (!target.closest(`#${dropdownID}`)) setIsDropdown(false)
            }
          })
    })

    const selectOption = (licenceNo?: string, force = false) => {
      selected.value = licenceNo
      setIsDropdown(false)
      if (licenceNo || force) emit('onLicenceNo', selected.value)
    }

    const selectAll = () => selectOption(undefined, true)
    watch(() => props.clinicUID, () => selectOption(undefined, true))
    watch(() => props.resetSelectedToggle, () => selectOption(undefined, true))
    watch(() => props.cabinetUID, () => {
      if (!props.cabinetUID) return
      if (!selected.value) return
      if (selected.value === licenceNoByCabinet.value) return
      selectOption(undefined, true)
    })
    return {
      isDropdown,
      dropdownID,
      setIsDropdown,
      licenceNos,
      selected,
      title,
      selectOption,
      selectAll,
      buttonText,
      licenceNoSearch,
      filteredLicenceNos
    }
  }
})
