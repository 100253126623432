
import {defineComponent, ref} from 'vue'
import ModalWrapper from "../../../utils/modal/ModalWrapper.vue";
import ClinicCabinetForm from "@/components/forms/ClinicCabinetForm.vue";
import {AddCabinetInput} from '@/shared/types'
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "AddClinicCabinetModal",
  components: {ClinicCabinetForm, ModalWrapper},
  emits: {
    onClose: () => true
  },
  setup() {
    const cabinetsStore = useCabinetsStore()
    const clinicsStore = useClinicsStore()
    const isOpen = ref(true)
    const isSaved = ref(false)
    const onCabinetAdd = async (cabinet: AddCabinetInput) => {
      await cabinetsStore.addCabinet(cabinet)
      await clinicsStore.setClinics(true)
      isSaved.value = true

    }
    return {isOpen, onCabinetAdd, isSaved}
  }
})
