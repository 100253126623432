
import {computed, defineComponent, ref} from 'vue'
import {PropType} from "vue";
import {CabinetDto} from "@/shared/types";
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import EditClinicCabinetModal from "@/components/clinics/cabinets/EditClinicCabinetModal.vue";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import CopyButton from "@/components/utils/buttons/CopyButton.vue";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  components: {CopyButton, AreYouSureModal, EditClinicCabinetModal, BaseTableEditDeleteButtons},
  props: {
    cabinet: {
      type: Object as PropType<CabinetDto>,
      required: true
    }
  },
  name: "ClinicsCabinetsListItem",
  setup(props) {
    const clinicsStore = useClinicsStore()
    const cabinetsStore = useCabinetsStore()

    const clinicsState = clinicsStore.getState()
    const clinic = computed(() => clinicsState.clinics.find(clinic => clinic.cabinetsUID.includes(props.cabinet.uid)))

    const userStore = useUserStore()
    const userState = userStore.getState()
    const isAdmin = computed(() => userState.admin)

    const editCabinetModal = ref(false)
    const onEditCabinet = () => editCabinetModal.value = true
    const deleteCabinet = async () => {
      await cabinetsStore.deleteCabinet({cabinetUID: props.cabinet.uid, clinicUID: props.cabinet.clinicUID})
      await clinicsStore.setClinics(true)
    }

    const deleteCabinetModal = ref(false)
    const onDeleteModalClose = async (action: boolean) => {
      if (action) await deleteCabinet()
      deleteCabinetModal.value = false
    }

    const copyCabinetUID = () => {

      const textArea: HTMLTextAreaElement = document.createElement('textarea')

      //
      // *** This styling is an extra step which is likely not required. ***
      //
      // Why is it here? To ensure:
      // 1. the element is able to have focus and selection.
      // 2. if the element was to flash render it has minimal visual impact.
      // 3. less flakyness with selection and copying which **might** occur if
      //    the textarea element is not visible.
      //
      // The likelihood is the element won't even render, not even a
      // flash, so some of these are just precautions. However in
      // Internet Explorer the element is visible whilst the popup
      // box asking the user for permission for the web page to
      // copy to the clipboard.
      //

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed'
      textArea.style.top = '0'
      textArea.style.left = '0'

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em'
      textArea.style.height = '2em'

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = '0'

      // Clean up any borders.
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent'

      textArea.value = props.cabinet.uid

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      document.execCommand('copy')

      document.body.removeChild(textArea)
    }

    return {onEditCabinet, editCabinetModal, deleteCabinetModal, onDeleteModalClose, clinic, copyCabinetUID,isAdmin}
  }
})
